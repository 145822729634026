<template>
  <div v-loading="loadingWithHash" class="fullheight">
    <div id="map" class="fullheight pebblo-public-map">
      <el-alert
        v-if="!loading && !sourceUrl"
        title="Veuillez renseigner une source de carte"
        type="error"
        center
        :closable="false"/>
      <div
        id="map-controls"
        v-if="!loading && sourceUrl">
        <el-button-group>
        <el-button icon="el-icon-zoom-out" :disabled="ctrlMinZoomDisabled" @click="zoomOut()"/>
        <el-button icon="el-icon-zoom-in" :disabled="ctrlMaxZoomDisabled" @click="zoomIn()"/>
        </el-button-group>
      </div>
      <l-map
        v-if="!loading && sourceUrl && hashFromLoadCompleted"
        class="fullheight"
        ref="map"
        :crs="crs"
        :bounds="bounds"
        :minZoom="minZoom"
        :maxZoom="maxZoom"
        :zoom="zoom"
        :center="center"
        :options="{zoomSnap: 0.25}"
        @zoomend="updateCurrentZoom($event)"
        @update:bounds="refreshRouterHashBounds($event)"
        >
        <l-tile-layer
          :url="sourceUrl"
          :options="{maxZoom: maxZoom, minZoom: minZoom}"
          v-if="isTileLayer"/>
        <l-image-overlay
          v-if="!isTileLayer"
          :bounds="imageBounds"
          :url="sourceUrl"/>
        <l-geo-json
          v-for="geojson of displayedGeojsons"
          v-bind:key="geojson.id"
          :geojson="geojson.geojson"
          :optionsStyle="geojson.style"
          @click="showPoi(geojson)"/>
        <l-marker
          v-for="poi of displayedPois"
          v-bind:key="poi.id"
          :icon="poi.icon"
          :lat-lng="poi.latlng"
          @click="showPoi(poi)"/>
      </l-map>
      <transition name="poi-info-slide">
      <div id="poi-info" v-if="currentPoi" :class="{fullscreen: isCurrentPoiFullscreen}">
        <div class="poi-navigation">
          <template v-if="currentPoiMedias.length > 1">
            <el-button icon="el-icon-arrow-left" type="text" class="icon-poi-arrow-left" @click="$refs.carousel.advancePage('backward')"/>
            <el-button icon="el-icon-arrow-right" type="text" class="icon-poi-arrow-right" @click="$refs.carousel.advancePage()"/>
          </template>
          <el-button icon="el-icon-close" type="text" class="icon-poi-close" @click="showPoi()"/>
        </div>
        <carousel
          class="poi-carousel"
          ref="carousel"
          v-if="currentPoiMedias.length"
          :perPage="1"
          :paginationColor="'#f0f0f0'"
          @mounted="$refs.carousel.render()"
          @pageChange="onCarouselPageChange($event)">
          <slide
            v-for="media of currentPoiMedias"
            v-bind:key="media.image_url">
            <div class="image">
              <img v-if="isCurrentPoiFullscreen" :src="media.image_url"/>
              <img v-else :src="media.image_url | asset(340, 256, false)"/>
              <div class="legend" v-if="tr(media.legend)">{{ tr(media.legend) }}</div>
            </div>
          </slide>
        </carousel>
        <div class="poi-info-content">
          <div
            v-for="entry of poiInfo"
            v-bind:key="entry.key"
            :class="['poi-entry-row', 'p-' + entry.name + '-row', entry.cssClass]">
            <div :class="['poi-entry-title', 'p-' + entry.name + '-title']">{{ entry.title }}</div>
            <div :class="['poi-entry-content', 'p-' + entry.name + '-content']" v-html="entry.content"/>
          </div>
        </div>
        <div class="poi-carousel-dots" v-if="currentPoiMedias.length > 1">
          <div
            v-for="(media, index) in currentPoiMedias"
            v-bind:key="media.image_url"
            @mousedown="$refs.carousel.goToPage(index)"
            :class="['poi-carousel-dot', index === currentPoiMediaIndex ? 'poi-carousel-dot-selected' : '']">
            <template v-if="index === currentPoiMediaIndex"><font-awesome-icon size="xs" :icon="['fas', 'circle']" /></template>
            <template v-else><font-awesome-icon size="xs" :icon="['far', 'circle']" /></template>
          </div>
        </div>
      </div>
      </transition>
      <transition name="category-slide">
        <div id="categories" v-show="showCategories">
          <el-tree
            :data="categories"
            show-checkbox
            node-key="id"
            ref="treeCategories"
            :default-checked-keys="categoriesKeys"
            @check-change="updateDisplayedMarkers()"
            :props="categoriesProps">
            <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>
                    <img v-if="data.icon_url" :src="data.icon_url | asset(64, 64)" class="icon-image">
                    {{ data.label }}
                </span>
            </span>
          </el-tree>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import max from 'lodash/max'
import min from 'lodash/min'
import L from 'leaflet'
import { Carousel, Slide } from 'vue-carousel'
import { asset } from '../utils'
import {
  LMap, LTileLayer, LImageOverlay, LMarker, LGeoJson
} from 'vue2-leaflet'
import URLON from 'urlon'

export default {
  name: 'PublicMap',
  props: ['appID', 'mapID'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LImageOverlay,
    LGeoJson,
    Carousel,
    Slide
  },

  data () {
    return {
      loading: true,
      minZoom: undefined,
      maxZoom: undefined,
      zoom: undefined,
      isTileLayer: false,
      isIndoor: false,
      sourceUrl: true,
      crs: null,
      bounds: null,
      imageBounds: null,
      pois: [],
      loadedPoisIds: [],
      displayedPois: [],
      geojsons: [],
      displayedGeojsons: [],
      center: undefined,
      currentPoi: null,
      poiInfo: [],
      currentMap: null,
      currentZoom: 0,
      showCategories: true,
      zoomSnap: 1,
      loadingPoisOffset: 0,
      loadingPoisLimit: 50,
      categories: [],
      categoriesKeys: [],
      hashNavigation: '',
      hashBounds: null,
      hashPoi: null,
      hashIgnoreChanges: true,
      hashPushNext: false,
      hashFromLoad: null,
      hashFromLoadPrepared: false,
      hashFromLoadPoiLoaded: false,
      hashFromLoadCompleted: false,
      categoriesProps: {
        children: 'children',
        label: 'label'
      }
    }
  },

  methods: {
    refreshRouterHashBounds (ev) {
      this.hashBounds = {
        s: ev.getSouth().toFixed(6),
        w: ev.getWest().toFixed(6),
        n: ev.getNorth().toFixed(6),
        e: ev.getEast().toFixed(6),
      }
      this.refreshRouterHash()
    },
    refreshRouterHashPoi () {
      this.hashPoi = this.currentPoi ? this.currentPoi.id : ''
      this.refreshRouterHash(true)

      // set navigation title
      let title = `${this.mapTitle}`;
      if (this.poiInfo && this.poiInfo[0]) {
        title += ` - ${this.poiInfo[0].content}`
      }
      document.title = title;
    },
    refreshRouterHash (push) {
      this.hashNavigation = URLON.stringify({
        p: this.hashPoi || '',
        b: this.hashBounds
      })
      if (window.location.hash === this.hashNavigation) {
        return;
      }
      if (this.hashIgnoreChanges || this.loading) {
        return;
      }
      try {
        if (push === true || this.hashPushNext) {
          if (push === true) {
            this.hashPushNext = true;
          } else {
            this.hashPushNext = false;
          }
          this.$router.push({
            hash: this.hashNavigation,
            query: this.$route.query,
          }).catch(() => {})
        } else {
          this.$router.replace({
            hash: this.hashNavigation,
            query: this.$route.query,
          }).catch(() => {})
        }
      } catch(e) {}
    },
    updateCurrentZoom (ev) {
      this.currentZoom = ev.target._zoom
    },
    zoomIn () {
      this.zoom = min([this.maxZoom, this.zoom + this.zoomSnap])
    },
    zoomOut () {
      this.zoom = max([this.minZoom, this.zoom - this.zoomSnap])
    },
    loadPoi (poi) {
      let position = null

      // if the user is logged, state is indicated in the output.
      // otherwise it's a public API request, therefore state doesn't show
      if (poi.state !== undefined) {
        // user logged, state can be checked
        if (poi.state !== 'published') {
          return null
        }
      }

      if (this.isIndoor) {
        position = poi.indoor_position
      } else {
        position = poi.outdoor_position
      }
      if (!position) return
      if (position.type === 'Point') {
        this.loadedPoisIds.push(poi.id)
        return this.pushPoi(poi, position)
      } else if (position.type === 'Polygon') {
        this.loadedPoisIds.push(poi.id)
        return this.pushGeojson(poi, position)
      }
      return null
    },
    updateDisplayedMarkers () {
      let keys = this.$refs.treeCategories.getCheckedKeys()
      let poiList = this.$route.query.poiList

      // filter pois
      let pois = this.pois
      if (poiList !== undefined) {
        pois = pois.filter(entry => {
          return (poiList.indexOf(entry.id) >= 0)
        })
      }

      pois = pois.filter(entry => {
        if (entry.data.categories.length === 0) {
          return true
        }
        for (let categoryId of entry.data.categories) {
          if (keys.indexOf(categoryId) >= 0) {
            return true
          }
        }
        return false
      })
      this.displayedPois = pois

      // filter geojsons
      let geojsons = this.geojsons
      if (poiList !== undefined) {
        geojsons = geojsons.filter(entry => {
          return (poiList.indexOf(entry.id) >= 0)
        })
      }

      geojsons = geojsons.filter(entry => {
        if (entry.data.categories.length === 0) {
          return true
        }
        for (let categoryId of entry.data.categories) {
          if (keys.indexOf(categoryId) >= 0) {
            return true
          }
        }
        return false
      })
      this.displayedGeojsons = geojsons
    },
    pushGeojson (poi, position) {
      let style = {}
      if (poi.properties !== undefined) {
        if (poi.properties['stroke'] !== undefined) {
          style['color'] = poi.properties['stroke']
        }
        if (poi.properties['stroke-width'] !== undefined) {
          style['weight'] = poi.properties['stroke-width']
        }
        if (poi.properties['fill'] !== undefined) {
          style['fillColor'] = poi.properties['fill']
        }
        if (poi.properties['fill-opacity'] !== undefined) {
          style['fillOpacity'] = poi.properties['fill-opacity']
        }
        if (poi.properties['opacity'] !== undefined) {
          style['opacity'] = poi.properties['opacity']
        }
      }
      let newPoi = {
        id: poi.id,
        data: poi,
        geojson: position,
        style: style
      }
      this.geojsons.push(newPoi)
      return newPoi
    },
    pushPoi (poi, position) {
      let latlng = position.coordinates
      let icon = undefined
      if (poi.icon_url) {
        let md = poi.icon_metadata
        let useCustomSize = md.use_custom
        let iwidth, iheight
        let assetUrl

        // determinate the ratio
        let oWidth = md.original_width || 48
        let oHeight = md.original_height || 48
        let ratio = oWidth / oHeight

        // determinate the icon width/height
        if (useCustomSize === undefined) {
          useCustomSize = true
        }

        if (useCustomSize) {
          iwidth = md.width || oWidth
          iheight = md.height || oHeight
          assetUrl = asset(poi.icon_url, iwidth, iheight)
        } else {
          let boxSize = md.box_size
          if (boxSize === undefined) {
            boxSize = 48
          }
          iwidth = boxSize
          iheight = boxSize
          if (iwidth > iheight) {
            iwidth = iheight * ratio
          } else {
            iheight = iwidth / ratio
          }
          assetUrl = asset(poi.icon_url, boxSize)
        }

        // determinate the anchor
        let anchor = md.anchor || 'center'
        let ax, ay
        if (anchor === 'center') {
          ax = iwidth / 2
          ay = iheight / 2
        } else if (anchor === 'top') {
          ax = iwidth / 2
          ay = 0
        } else if (anchor === 'top-left') {
          ax = 0
          ay = 0
        } else if (anchor === 'top-right') {
          ax = iwidth
          ay = 0
        } else if (anchor === 'left') {
          ax = 0
          ay = iheight / 2
        } else if (anchor === 'right') {
          ax = iwidth
          ay = iheight / 2
        } else if (anchor === 'bottom-right') {
          ax = 0
          ay = iheight
        } else if (anchor === 'bottom-right') {
          ax = iwidth
          ay = iheight / 2
        } else {
          ax = iwidth / 2
          ay = iheight
        }

        icon = new L.divIcon({
          html: '<img src="' + assetUrl + '" class="divicon"/>',
          iconSize: [iwidth, iheight],
          iconAnchor: [ax, ay]
        })
      }
      let newPoi = {
        id: poi.id,
        draggable: false,
        data: poi,
        latlng: L.latLng(latlng[1], latlng[0]),
        icon: icon
      }
      this.pois.push(newPoi)
      return newPoi
    },
    loadPois () {
      for (let poi of this.$store.state.pois) {
        if (this.loadedPoisIds.indexOf(poi.id) !== -1) continue
        this.loadPoi(poi)
      }
      this.updateDisplayedMarkers()
    },
    onCarouselPageChange (ev) {
      if (isNaN(ev)) return
      if (this.ignoreCarouselPageChange === true) return
      this.currentPoiMediaIndex = parseInt(ev)
      this.refreshDisplayedPoiFields()
    },
    selectImage (index) {
      this.currentPoiMediaIndex = index
      this.refreshDisplayedPoiFields()
    },
    async refreshDisplayedPoiFields (ignoreCarouselPageChange) {
      if (ignoreCarouselPageChange) {
        this.ignoreCarouselPageChange = true
        setTimeout(() => {
          this.ignoreCarouselPageChange = false
        }, 100)
      }

      let poiInfo = []

      // default fields from the app
      let layout = this.currentAppState.current_layout

      // if a media is selected, and it has a layout id, use it instead
      if (this.currentPoiMedias.length) {
        let media = this.currentPoiMedias[this.currentPoiMediaIndex]
        if (media && media.layout_id) {
          await this.$store.dispatch('ensureLayouts')
          layout = this.$store.state.layouts.find(x => x.id === media.layout_id)
        }
      }

      // fill the poi with all the translated_fields and default_fields
      let fields = layout.displayed_fields.poi_default_translated_fields
      let isFirstListItem = true
      for (const [fieldKey, field_] of Object.entries(fields)) {
        // FIXME: displayed_fields contain a copy, not the real one
        // find the updated version
        let field = this.currentAppState.fields.poi_default_translated_fields.find(
          x => x.field_name === field_.field_name)
        if (!field) {
          continue
        }
        if (field.type === 'string' || field.type === 'longstring') {
          let content = this.tf(this.currentPoi.data, field.field_name)
          if (content && content.length > 1) {
            let info = {
              key: fieldKey,
              name: field.field_name,
              title: this.tr(field.displayed_name),
              cssClass: 'poi-entry-style-' + (field.style || 'default'),
              content: content
            }
            if (field.style === 'list-item') {
              if (isFirstListItem) {
                isFirstListItem = false
                info.cssClass += ' poi-entry-style-list-item-first'
              }
            }
            poiInfo.push(info)
          }
        }
      }
      this.poiInfo = poiInfo
    },
    showPoi (poi) {
      if (poi === undefined) {
        this.currentPoi = null
        this.poiInfo = []
        this.refreshRouterHashPoi()
        return
      }
      if (poi.data.content_fields.linked_360_map) {
        this.$router.push({name: 'public-map-360', params: {
          appID: this.$route.params.appID,
          mapID: poi.data.content_fields.linked_360_map
        }, query: this.$route.query});
        return;
      }

      this.currentPoiMediaIndex = 0
      this.currentPoi = cloneDeep(poi)
      this.refreshDisplayedPoiFields(true)
      this.refreshRouterHashPoi()
    },
    async prepareLoading () {
      // having a async loading allow leaflet widget to be reconstructed
      // from scratch, in case CRS changes, as it's not supported in
      // leaflet in live
      this.loading = true
    },
    async prepare () {
      await this.prepareLoading()
      this.currentMap = cloneDeep(this.$store.state.currentMap)
      this.sourceUrl = this.getSourceUrl(this.currentMap.source_url)

      // check if it's a tile or image
      if (this.currentMap.source_url.indexOf('{z}') !== -1) {
        this.isTileLayer = true
      }

      if (this.currentMap.is_indoor) {
        this.isIndoor = true
        this.crs = L.CRS.Simple
      } else {
        this.isIndoor = false
        this.crs = L.CRS.EPSG3857
      }

      if (this.currentMap.bounds !== null && Object.entries(this.currentMap.bounds).length) {
        let cbounds = this.currentMap.bounds

        // fix for inverted bounds in apps 2
        if (cbounds.min_lon > 10 && this.currentAppState.id === 2) {
          let swp = cbounds.min_lat
          cbounds.min_lat = cbounds.min_lon
          cbounds.min_lon = swp
          swp = cbounds.max_lat
          cbounds.max_lat = cbounds.max_lon
          cbounds.max_lon = swp
        }

        if (this.isIndoor) {
          cbounds.min_lat = (cbounds.min_lat === undefined) ? 0 : cbounds.min_lat
          cbounds.min_lon = (cbounds.min_lon === undefined) ? 0 : cbounds.min_lon
          cbounds.max_lat = (cbounds.max_lat === undefined) ? 100 : cbounds.max_lat
          cbounds.max_lon = (cbounds.max_lon === undefined) ? 100 : cbounds.max_lon
        } else {
          cbounds.min_lat = (cbounds.min_lat === undefined) ? -85 : cbounds.min_lat
          cbounds.min_lon = (cbounds.min_lon === undefined) ? -180 : cbounds.min_lon
          cbounds.max_lat = (cbounds.max_lat === undefined) ? 85 : cbounds.max_lat
          cbounds.max_lon = (cbounds.max_lon === undefined) ? 180 : cbounds.max_lon
        }

        // is it possible that the map was edited but bounds are invalid.
        // in this case, just avoid to set anything.
        if (cbounds.min_lat === -85 &&
            cbounds.max_lat === 85 &&
            cbounds.min_lon === -180 &&
            cbounds.max_lon === 180) {
          this.bounds = null
        } else {
          this.bounds = this.imageBounds = [
            {lat: cbounds.min_lat, lon: cbounds.min_lon},
            {lat: cbounds.max_lat, lon: cbounds.max_lon}
          ]
        }
      } else if (this.isIndoor) {
        this.imageBounds = [
          {lat: 0, lon: 0},
          {lat: 100, lon: 100}
        ]
      }

      if (this.currentMap.default_zoom !== null) {
        this.zoom = this.currentZoom = this.currentMap.default_zoom
      } else {
        this.zoom = 1
      }
      if (this.currentMap.min_zoom !== null) {
        this.minZoom = this.currentMap.min_zoom
      }
      if (this.currentMap.max_zoom !== null) {
        this.maxZoom = this.currentMap.max_zoom
      }

      this.map_default_content_fields = this.$store.state.app.fields.map_default_content_fields
      this.map_default_translated_fields = this.$store.state.app.fields.map_default_translated_fields
      this.loading = false


      this.hashFromLoadPrepared = true;
      this.loadHashFromLoad()

      this.$nextTick(() => {
        if (this.currentMap.center_lat === null) {
          this.center = [0, 0]
        } else {
          // eslint-disable-next-line
          this.center = new L.latLng(this.currentMap.center_lat, this.currentMap.center_lon)
        }
      })
    },

    _convertCategories (categories, keys) {
      var result = []
      for (let el of categories) {
        let label
        try {
          label = this.tf(el, 'name')
        } catch (e) {
          label = '(category ' + el.id + ' have no name)'
        }
        let children = null
        if (el.children !== undefined) {
          children = this._convertCategories(el.children, keys)
        }
        keys.push(el.id)
        result.push({
          id: el.id,
          label: label,
          icon_url: el.icon_url,
          children: children
        })
      }
      return result
    },

    updateCategories () {
      let keys = []
      this.categories = this._convertCategories(this.$store.state.categories, keys)
      if (this.$route.query.categoriesChecked !== undefined) {
        keys = this.$route.query.categoriesChecked.split(',').map(Number)
      }
      this.categoriesKeys = keys
    },

    asyncLoadPois () {
      this.$store.dispatch('loadPois', {
        appID: this.appID,
        mapID: this.poisMapID,
        limit: this.loadingPoisLimit,
        offset: this.loadingPoisOffset
      }).then((response) => {
        if (response.data.next !== null) {
          this.loadingPoisOffset += this.loadingPoisLimit
          setTimeout(() => {
            this.asyncLoadPois()
          }, 0)
        } else {
          this.hashFromLoadPoiLoaded = true;
          this.loadHashFromLoad()
        }
      })
    },

    loadHashFromLoad() {
      this.setFromHash(this.hashFromLoad, false);
      if (!this.hashFromLoadCompleted) {
        this.hashFromLoadCompleted = this.$refs.map !== undefined;
      }
      if (this.hashFromLoadPoiLoaded && this.hashFromLoadPrepared) {
        this.hashFromLoad = null;
        this.hashFromLoadCompleted = true;
      }
    },

    setFromHash (val, ignoreChanges) {
      val = val || null;
      if (val === null) {
        this.hashIgnoreChanges = ignoreChanges;
        return;
      }
      this.hashIgnoreChanges = true;
      try {
        let jval = URLON.parse(val);
        // load poi
        if (!jval.p) {
          this.showPoi();
        } else {
          let poi;
          this.pois.find(poi => {
            if (poi.id === jval.p) {
              this.showPoi(poi);
              return true;
            }
            return false;
          });
          this.geojsons.find(poi => {
            if (poi.id === jval.p) {
              this.showPoi(poi);
              return true;
            }
            return false;
          });
        }
        // set bounds
        let bounds = [
          {lat: parseFloat(jval.b.s), lon: parseFloat(jval.b.w)},
          {lat: parseFloat(jval.b.n), lon: parseFloat(jval.b.e)},
        ]
        try {
          this.$refs.map.mapObject.fitBounds(bounds, {animate: false});
        } catch(e) {}
        this.bounds = bounds;
      } catch (e) {
        console.log(e);
      }

      this.hashIgnoreChanges = ignoreChanges;
    },

  },

  computed: {
    loadingWithHash () {
      return this.loading && this.hashFromLoadCompleted;
    },
    ctrlMaxZoomDisabled () {
      if (this.maxZoom !== undefined) return this.currentZoom >= this.maxZoom
      return false
    },
    ctrlMinZoomDisabled () {
      if (this.minZoom !== undefined) return this.currentZoom <= this.minZoom
      return false
    },
    languages () {
      return this.$store.state.languages
    },
    currentAppState () {
      return this.$store.state.app
    },
    currentMapState () {
      return this.$store.state.currentMap
    },
    mapTitle () {
      return this.$store.getters.getMapTitle
    },
    isCurrentPoiFullscreen () {
      if (this.currentPoi === undefined) return false
      if (this.$route.query.fullscreenPois === '1') return true
      if (this.currentPoi.data.content_fields.fullscreen_item === true) return true
      return false
    },
    currentPoiMedias () {
      if (this.currentPoi === undefined) return []
      return this.currentPoi.data.media.filter(entry => {
        let platform = (entry.platform || [])
        if (platform.length === 0) return true
        if (platform.indexOf('web') !== -1) return true
      })
    }
  },

  watch: {
    '$route.hash': {
      handler (val, oldVal) {
        if (this.hashIgnoreChanges) return;
        // hash starts with an #, so remove it
        val = val.substr(1)
        if (val !== this.hashNavigation) {
          this.setFromHash(val, false);
        }
      },
    },
    '$store.state.categories' () {
      this.updateCategories()
    },
    '$store.state.pois' () {
      if (this.loading) return
      this.loadPois()
    },
    '$store.state.currentMap': {
        handler (val, oldVal) {
          if (this.loading) return
          let needReload = false
          needReload |= oldVal.source_url !== val.source_url
          needReload |= oldVal.is_indoor !== val.is_indoor
          needReload |= oldVal.center_lat !== val.center_lat
          needReload |= oldVal.center_lon !== val.center_lon
          needReload |= oldVal.bounds.min_lat !== val.bounds.min_lat
          needReload |= oldVal.bounds.min_lon !== val.bounds.min_lon
          needReload |= oldVal.bounds.max_lat !== val.bounds.max_lat
          needReload |= oldVal.bounds.max_lon !== val.bounds.max_lon
          needReload |= oldVal.min_zoom !== val.min_zoom
          needReload |= oldVal.max_zoom !== val.max_zoom
          needReload |= oldVal.default_zoom !== val.default_zoom
          if (needReload) {
            this.prepare()
          }
        },
        deep: true
    }
  },

  created () {
    // load hash, and try to set it on the map asap.
    this.hashFromLoad = this.$route.hash.substr(1);
    if (this.hashFromLoad.length === 0) {
      this.hashFromLoadCompleted = true;
      this.setFromHash(this.hashFromLoad, false);
    } else {
      this.setFromHash(this.hashFromLoad, true);
    }

    if (this.$route.query.hideCategories) {
      this.showCategories = false
    }

    if (this.$route.query.poisMapId !== undefined) {
      this.poisMapID = parseInt(this.$route.query.poisMapId)
    } else {
      this.poisMapID = this.mapID
    }

    if (this.$route.query.zoomSnap !== undefined) {
      try {
        this.zoomSnap = parseFloat(this.$route.query.zoomSnap)
      } catch (e) {
        this.zoomSnap = 1
      }
    }

    setTimeout(async () => {
      await this.$store.dispatch('ensureApp', {
        appID: this.appID
      })
      await this.$store.dispatch('ensureMap', {
        appID: this.appID,
        mapID: this.mapID
      })

      await this.$store.dispatch('loadCategories', {
        appID: this.appID
      })
      await this.prepare()

      setTimeout(() => {
        this.asyncLoadPois()
      }, 0)

    }, 0);
  }
}
</script>

<style scoped>
.image {
  width: 100%;
  display: block;
}

#map {
  overflow-x: hidden;
  margin: -20px;
}

#map-controls {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1500;
  text-align: center;
  position: absolute;
  margin-top: 10px;
}

.leaflet-container {
  background: transparent !important;
}

#poi-info {
  position: absolute;
  background: #f0f0f0;
  color: #030303;
  width: auto;
  top: 20px;
  left: 20px;
  z-index: 2000;
  max-width: 400px;
  max-height: 95%;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 768px) {
  #poi-info {
    position: fixed;
    top: 20px;
    left: 20px;
    bottom: 20px;
    right: 20px;
    max-width: none;
  }
}

#poi-info .poi-entry-title {
  font-weight: bold;
  padding: 2px 0;
  text-transform: capitalize;
}

#poi-info .poi-entry-content {
  margin-bottom: 10px;
}

#poi-info .image {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

#poi-info .legend {
  height: auto;
  width: 100%;
  background-color: white;
  color: black;
  bottom: 0px;
  position: fixed;
  padding: 10px;
}

#poi-info .poi-info-content {
  overflow-y: auto;
  padding: 20px;
}

.poi-navigation > button {
  font-size: 36px;
  color: black;
}

.poi-navigation {
  order: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .icon-poi-close,
  .icon-poi-arrow-right,
  .icon-poi-arrow-left {
    font-size: 26px;
    margin-right: 10px;
    margin-left: 0px;
  }

  .icon-poi-arrow-right::after,
  .icon-poi-arrow-left::after {
    border-right: 1px solid black;
    height: 18px;
    display: block;
    position: absolute;
    right: -5px;
    top: 50%;
    margin-top: -10px;
    content: " ";
  }
  .icon-poi-close {
    font-size: 30px;
    margin-right: 14px;
  }
}

#poi-info .poi-entry-content {
  white-space: pre-wrap;
}

#categories {
  background-color: white;
  padding: 10px;
  position: absolute;
  z-index: 1600;
  bottom: 10px;
  left: 10px;
}

/* PBA move in a custom field */
#poi-info {
  right: 0;
  left: auto;
  top: 0;
  bottom: 0;
  background-color: white;
  max-height: 100%;
  font-size: 14px;
  width: 365px;
}

#poi-info .image {
  background-color: white;
}

#poi-info .icon-poi-close {
  font-size: 36px;
}

#poi-info .poi-entry-content {
  text-align: right;
  width: 100%;
  align-self: flex-end;
  margin-bottom: 0;
}

#poi-info .p-title-title,
#poi-info .p-text1-title,
#poi-info .p-text2-title {
  display: none;
}

#poi-info .p-title-content {
  font-size: 20px;
  text-align: left;
  margin-bottom: 10px;
}

#poi-info .poi-entry-row {
  display: flex;
  border-bottom: 1px solid #706f6f;
}

#poi-info .poi-entry-row .poi-entry-content {
  padding: 7px;
  padding-right: 0;
}
#poi-info .poi-entry-row .poi-entry-title {
  padding: 7px;
  padding-left: 0;
}

#poi-info .poi-entry-row .p-title-content,
#poi-info .poi-entry-row .p-text1-content,
#poi-info .poi-entry-row .p-text2-content {
  text-align: left;
  margin-bottom: 10px;
  padding: 0;
}

#poi-info .p-title-row,
#poi-info .p-text1-row,
#poi-info .p-text2-row {
  border-bottom: 0px solid transparent;
}

.poi-info-slide-enter-active, .poi-info-slide-leave-active {
  transition: all .5s ease;
  transform: translateX(0);
}

.poi-info-slide-enter, .poi-info-slide-leave-to {
  transform: translateX(100%);
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
.icon-image {
    width: 24px;
    vertical-align: middle;
}

#poi-info .poi-carousel-dots {
  display: none;
  cursor: pointer;
}

</style>

<style>
.el-loading-mask {
  background-color: rgba(255, 255, 255, 0)
}
.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}
.leaflet-control-container {
  display: none;
}
.VueCarousel-pagination {
  margin-top: -20px !important;
}
</style>
